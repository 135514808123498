<template>
	<div>
		<div class="">
			<div>
				<el-row type="flex" justify="center">
					<el-col :span="12">
						<el-form :model="ruleForm" status-icon ref="ruleForm" label-width="100px" class="">
							<el-form-item label="用户头像">
								<el-upload class="avatar-uploader" :headers="{'api-name':'web','api-token':u_token}"
									:action="action_upload" :show-file-list="false" :on-success="handleAvatarSuccess"
									:before-upload="beforeAvatarUpload">
									<img v-if="ruleForm.headimg" :src="ruleForm.headimg" class="avatar">
									<i v-else class="el-icon-plus avatar-uploader-icon"></i>
								</el-upload>
							</el-form-item>
							<el-form-item label="登录账号">
								<el-input v-model="info.account" disabled maxlength="20" placeholder=""></el-input>
							</el-form-item>
							<div v-if="ruleForm.u_type==1">
								<el-form-item label="姓名" :required="true">
									<el-input v-model="ruleForm.username" maxlength="20" placeholder="请输入"></el-input>
								</el-form-item>
								<el-form-item label="手机号" :required="true">
									<el-input v-model="ruleForm.phone" maxlength="60" placeholder="请输入"></el-input>
								</el-form-item>
								<el-form-item label="身份证号码" :required="true">
									<el-input v-model="ruleForm.idcode" maxlength="80" placeholder="请输入"></el-input>
								</el-form-item>
								<el-form-item label="单位" :required="true">
									<el-cascader style="width: 100%;" placeholder="" v-model="ruleForm.cateids"
										:options="cates_list" :props="{label:'name',children:'sub',value:'id'}"
										:filterable="true" />
								</el-form-item>
								<el-form-item label="专业">
									<el-input v-model="ruleForm.job_name" maxlength="60" placeholder="请输入"></el-input>
								</el-form-item>
								<el-form-item label="班级">
									<el-input v-model="ruleForm.class" maxlength="60" placeholder="请输入"></el-input>
								</el-form-item>
								<el-form-item label="学号">
									<el-input v-model="ruleForm.stu_num" maxlength="60" placeholder="请输入"></el-input>
								</el-form-item>
								<el-form-item label="籍贯">
									<el-input v-model="ruleForm.base_hometown" maxlength="60" placeholder="请输入"></el-input>
								</el-form-item>
								<el-form-item label="出生年月">
									<el-date-picker v-model="ruleForm.base_birthday" value-format="yyyy-MM-dd"
										format="yyyy-MM-dd" :clearable="false" type="date" placeholder="请选择"
										style="width: 100%;">
									</el-date-picker>
								</el-form-item>
							</div>
							<div v-if="ruleForm.u_type==2">
								<el-form-item label="用户名" :required="true">
									<el-input v-model="ruleForm.account" maxlength="20" placeholder="请输入"></el-input>
								</el-form-item>
								<el-form-item label="手机号" :required="true">
									<el-input v-model="ruleForm.phone" maxlength="60" placeholder="请输入"></el-input>
								</el-form-item>
								<el-form-item label="单位" :required="true">
									<el-cascader style="width: 100%;" placeholder="" v-model="ruleForm.cateids"
										:options="cates_list" :props="{label:'name',children:'sub',value:'id'}"
										:filterable="true" />
								</el-form-item>
								<el-form-item label="所在地">
									<el-cascader style="width: 100%;" placeholder="请选择" v-model="ruleForm.area"
										:options="region_cates_list" :props="{label:'name',children:'sub',value:'name'}"
										:filterable="true" />
								</el-form-item>
								<el-form-item label="所在单位">
									<el-input v-model="ruleForm.company_name" maxlength="60" placeholder="请输入"></el-input>
								</el-form-item>
								<el-form-item label="职务">
									<el-input v-model="ruleForm.now_job" maxlength="60" placeholder="请输入"></el-input>
								</el-form-item>
							</div>
							<div v-if="ruleForm.u_type==3">
								<el-form-item label="用户名" :required="true">
									<el-input v-model="ruleForm.account" maxlength="20" placeholder="请输入"></el-input>
								</el-form-item>
								<el-form-item label="手机号" :required="true">
									<el-input v-model="ruleForm.phone" maxlength="60" placeholder="请输入"></el-input>
								</el-form-item>
								<el-form-item label="单位" :required="true">
									<el-cascader style="width: 100%;" placeholder="" v-model="ruleForm.cateids"
										:options="cates_list" :props="{label:'name',children:'sub',value:'id'}"
										:filterable="true" />
								</el-form-item>
								<el-form-item label="所在单位全称">
									<el-input v-model="ruleForm.company_name" maxlength="60" placeholder="请输入"></el-input>
								</el-form-item>
								<el-form-item label="职务">
									<el-input v-model="ruleForm.now_job" maxlength="60" placeholder="请输入"></el-input>
								</el-form-item>
								<el-form-item label="籍贯">
									<el-input v-model="ruleForm.base_hometown" maxlength="60" placeholder="请输入"></el-input>
								</el-form-item>
								<el-form-item label="任教学科">
									<el-input v-model="ruleForm.teach_subject" maxlength="60" placeholder="请输入"></el-input>
								</el-form-item>
								<el-form-item label="出生年月">
									<el-date-picker v-model="ruleForm.base_birthday" value-format="yyyy-MM-dd"
										format="yyyy-MM-dd" :clearable="false" type="date" placeholder="请选择"
										style="width: 100%;">
									</el-date-picker>
								</el-form-item>
							</div>
							<div v-if="ruleForm.u_type==4">
								<el-form-item label="姓名" :required="true">
									<el-input v-model="ruleForm.username" maxlength="20" placeholder="请输入"></el-input>
								</el-form-item>
								<el-form-item label="性别" :required="true">
									<el-select v-model="ruleForm.base_sex" placeholder="请选择" class="max_w">
										<el-option v-for="item in sex_options" :key="item.value" :label="item.label"
											:value="item.value">
										</el-option>
									</el-select>
								</el-form-item>
								<el-form-item label="身份证号码" :required="true">
									<el-input v-model="ruleForm.idcode" maxlength="80" placeholder="请输入"></el-input>
								</el-form-item>
								<el-form-item label="手机号" :required="true">
									<el-input v-model="ruleForm.phone" maxlength="60" placeholder="请输入"></el-input>
								</el-form-item>
								<el-form-item label="所在地">
									<el-cascader style="width: 100%;" placeholder="请选择" v-model="ruleForm.area"
										:options="region_cates_list" :props="{label:'name',children:'sub',value:'name'}"
										:filterable="true" />
								</el-form-item>
								<el-form-item label="工作单位">
									<el-input v-model="ruleForm.company_name" maxlength="60" placeholder="请输入"></el-input>
								</el-form-item>
								<el-form-item label="任职单位">
									<el-cascader style="width: 100%;" placeholder="请选择" v-model="ruleForm.cateids"
										:options="cates_list" :props="{label:'name',children:'sub',value:'id'}"
										:filterable="true" />
								</el-form-item>
								<el-form-item label="最高学位">
									<el-select v-model="ruleForm.max_degree" placeholder="请选择" class="max_w">
										<el-option v-for="item in max_degree.list" :key="item.value" :label="item.label"
											:value="item.value">
										</el-option>
									</el-select>
								</el-form-item>
								<el-form-item label="最高学历">
									<el-select v-model="ruleForm.max_edu" placeholder="请选择" class="max_w">
										<el-option v-for="item in max_edu.list" :key="item.value" :label="item.label"
											:value="item.value">
										</el-option>
									</el-select>
								</el-form-item>
								<el-form-item label="专业">
									<el-input v-model="ruleForm.job_name" maxlength="60" placeholder="请输入"></el-input>
								</el-form-item>
								<el-form-item label="政治面貌">
									<el-select v-model="ruleForm.political_outlook" placeholder="请选择" class="max_w">
										<el-option v-for="item in political_outlook.list" :key="item.value"
											:label="item.label" :value="item.value">
										</el-option>
									</el-select>
								</el-form-item>
							</div>
							<div v-if="ruleForm.u_type==5">
								<el-form-item label="用户名" :required="true">
									<el-input v-model="ruleForm.account" maxlength="20" placeholder="请输入"></el-input>
								</el-form-item>
								<el-form-item label="手机号" :required="true">
									<el-input v-model="ruleForm.phone" maxlength="60" placeholder="请输入"></el-input>
								</el-form-item>
							</div>
							<br><br>
							<el-form-item class="">
								<el-button type="primary" round style="width: 200px;"
									@click="submitForm()">保存个人信息</el-button>
							</el-form-item>
						</el-form>
					</el-col>
				</el-row>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'UserInfo',
		components: {},
		props: [''],
		data() {
			return {
				u_token: localStorage.getItem('u_token'),
				action_upload: "",
				info: {},
				region_cates_list: [], //所在地
				cates_list: [], //学校
				ruleForm: {
					headimg: "",
					nickname: "",

					account: "",
					idcode: "",
					cateids: [],
					area: [],
					u_type: 1,
					edu_name: "",
					phone: "",
					username: "", //姓名
					base_sex: "",
					political_outlook: "",
					max_title: "", //最高职称
					max_edu: "", //最高学历
					max_degree: "", //最高学位
					nation: "", //民族
					company_name: "", //工作单位全称
					job_name: "", //职业专业
					now_job: "", //当前职务
					stu_num: "", //学号
					base_birthday: "", //出生年月
					base_hometown: "", //籍贯
					teach_subject: "", //任教学科
					class: "", //班级
				},
				school_props: {
					label: 'name',
					children: 'sub',
					value: 'id',
					lazy: false,
					lazyLoad() {}
				},
				area_props: {
					label: 'name',
					children: 'sub',
					value: 'name',
					lazy: false,
					lazyLoad() {}
				},
				sex_options: [{
					value: '男',
					label: '男'
				}, {
					value: '女',
					label: '女'
				}],
				register_options: [{
					value: 2,
					label: '教育厅/局管理员'
				}, {
					value: 3,
					label: '学校管理员'
				}, {
					value: 1,
					label: '在校学生'
				}, {
					value: 4,
					label: '中小学法治副校长'
				}, {
					value: 5,
					label: '其他'
				}],
				political_outlook: {
					list: [{
						label: "中共党员",
						value: "中共党员"
					}, {
						label: "中共预备党员",
						value: "中共预备党员"
					}, {
						label: "共青团员",
						value: "共青团员"
					}, {
						label: "民革党员",
						value: "民革党员"
					}, {
						label: "民盟盟员",
						value: "民盟盟员"
					}, {
						label: "民建会员",
						value: "民建会员"
					}, {
						label: "民进会员",
						value: "民进会员"
					}, {
						label: "农工党党员",
						value: "农工党党员"
					}, {
						label: "致公党党员",
						value: "致公党党员"
					}, {
						label: "九三学社社员",
						value: "九三学社社员"
					}, {
						label: "台盟盟员",
						value: "台盟盟员"
					}, {
						label: "无党派人士",
						value: "无党派人士"
					}, {
						label: "群众",
						value: "群众"
					}],
				},
				max_edu: {
					list: [{
						label: "研究生",
						value: "研究生"
					}, {
						label: "本科",
						value: "本科"
					}, {
						label: "专科",
						value: "专科"
					}]
				},
				max_degree: {
					list: [{
						label: "博士",
						value: "博士"
					}, {
						label: "硕士",
						value: "硕士"
					}, {
						label: "学士",
						value: "学士"
					}]
				},
			};
		},
		computed: {},
		beforeMount() {
			this.action_upload = this.$api.baseUrl + '/api/auth.center/upload';
		},
		mounted() {
			this.getUserInfo();
		},
		methods: {
			//头像上传成功回调
			handleAvatarSuccess(res) {
				this.ruleForm.headimg = res.data.url; //URL.createObjectURL(file.raw);//本地图片对象
			},
			//头像上传前校验
			beforeAvatarUpload(file) {
				const isJPG = file.type === 'image/jpeg';
				const isLt2M = file.size / 1024 / 1024 < 2;
				if (!isJPG) {
					this.$message.error('上传头像图片只能是 JPG 格式!');
				}
				if (!isLt2M) {
					this.$message.error('上传头像图片大小不能超过 2MB!');
				}
				return isJPG && isLt2M;
			},
			//获取用户信息详情
			getUserInfo() {
				const params = {

				}
				this.$api.auth.center.get(params).then((res) => {
					// 处理相应的业务
					if (res.code == 1) {
						this.info = res.data;
						Object.assign(this.ruleForm, this.info);
						// this.ruleForm.headimg = ;
						// this.ruleForm.username = this.info.username;
						// this.ruleForm.nickname = this.info.nickname;
						// this.ruleForm.idcode = this.info.idcode;
						// this.ruleForm.phone = this.info.phone;
						if (this.info.cateids) {
							var cate_arr = this.info.cateids.split(',');
							this.ruleForm.cateids = [];
							for (var i = 0; i < cate_arr.length; i++) {
								this.ruleForm.cateids.push(Number(cate_arr[i]))
							}
						}
						this.ruleForm.area = [];
						this.ruleForm.area.push(this.info.region_province, this.info.region_city, this.info
							.region_area)
						this.getCateIndexFN();
						this.getAreaCateIndexFN();
					} else {
						this.$message({
							message: res.info,
							type: 'error'
						});
					}
				}).catch(() => {}).finally(() => {
					// 关闭loading
				})
			},
			//检查输入
			checkSubmit(u_type) {
				var require_params = [{
					name: "用户名",
					key: "account",
					u_type: [2, 3, 5]
				}, {
					name: "姓名",
					key: "username",
					u_type: [1, 4]
				}, {
					name: "性别",
					key: "base_sex",
					u_type: [4]
				}, {
					name: "身份证号码",
					key: "idcode",
					u_type: [1, 4]
				}, {
					name: "单位",
					key: "cateids",
					u_type: [4]
				}, {
					name: "手机号",
					key: "phone",
					u_type: [1, 2, 3, 4, 5]
				}]
				for (var i = 0; i < require_params.length; i++) {
					if (require_params[i].u_type.indexOf(u_type) != -1) {
						if (typeof(this.ruleForm[require_params[i].key]) === 'object') {
							if (this.ruleForm[require_params[i].key].length == 0) {
								this.$message({
									message: '请完善' + require_params[i].name + '信息',
									type: 'error'
								});
								return false;
							}
						} else {
							if (typeof(this.ruleForm[require_params[i].key]) === 'string') {
								if (this.ruleForm[require_params[i].key] == '') {
									this.$message({
										message: '请完善' + require_params[i].name + '信息',
										type: 'error'
									});
									return false;
								}
							}
						}
					}
				}
				return true;
			},
			submitForm() {
				if (!this.ruleForm.username) {
					this.$message({
						message: '用户姓名不能为空',
						type: 'error'
					});
				}
				var check_res = this.checkSubmit(this.ruleForm.u_type);
				if (!check_res) {
					return;
				}
				if (this.ruleForm.phone) {
					if (!this.isPhone(this.ruleForm.phone)) {
						this.$message({
							message: '手机号输入不正确',
							type: 'error'
						});
						return;
					}
				}
				this.setFN();
			},
			//保存个人信息
			setFN() {
				const params = {
					headimg: this.ruleForm.headimg,
					nickname: this.ruleForm.nickname,

					idcode: this.ruleForm.idcode,
					cateids: this.ruleForm.cateids.join(','),
					region_province: this.ruleForm.area[0],
					region_city: this.ruleForm.area[1],
					region_area: this.ruleForm.area[2] ? this.ruleForm.area[2] : this.ruleForm.area[1],
					edu_name: this.ruleForm.edu_name,
					phone: this.ruleForm.phone,
					username: this.ruleForm.username,
					base_sex: this.ruleForm.base_sex,
					political_outlook: this.ruleForm.political_outlook,
					max_title: this.ruleForm.max_title, //最高职称
					max_edu: this.ruleForm.max_edu, //最高学历
					max_degree: this.ruleForm.max_degree, //最高学位
					nation: this.ruleForm.nation, //民族
					company_name: this.ruleForm.company_name, //单位全称
					job_name: this.ruleForm.job_name, //职业专业
					now_job: this.ruleForm.now_job, //当前职务
					stu_num: this.ruleForm.stu_num, //学号
					base_birthday: this.ruleForm.base_birthday, //出生年月
					base_hometown: this.ruleForm.base_hometown, //籍贯
					teach_subject: this.ruleForm.teach_subject, //任教学科
					class: this.ruleForm.class, //班级
				}
				this.$api.auth.center.set(params).then((res) => {
					// 处理相应的业务
					if (res.code == 1) {
						this.$message({
							message: '保存成功',
							type: 'success'
						});
						this.$emit('updateUserInfo');
					} else {
						this.$message({
							message: res.info,
							type: 'error'
						});
					}
				}).catch(() => {}).finally(() => {
					// 关闭loading
				})
			},
			//获取学校数据
			getCateIndexFN() {
				const params = {}
				this.$api.school.getCate(params).then((res) => {
					// 处理相应的业务
					this.cates_list = res.data;
				}).catch(() => {}).finally(() => {
					// 关闭loading
				})
			},
			//获取所在地数据
			getAreaCateIndexFN() {
				const params = {}
				this.$api.region.getCate(params).then((res) => {
					// 处理相应的业务
					this.region_cates_list = res.data;
				}).catch(() => {}).finally(() => {
					// 关闭loading
				})
			},
		},
		watch: {}
	}
</script>

<style>
	.avatar-uploader .el-upload {
		border: 1px dashed #d9d9d9;
		border-radius: 6px;
		cursor: pointer;
		position: relative;
		overflow: hidden;
	}

	.avatar-uploader .el-upload:hover {
		border-color: #409EFF;
	}

	.avatar-uploader-icon {
		font-size: 28px;
		color: #8c939d;
		width: 178px;
		height: 178px;
		line-height: 178px;
		text-align: center;
	}

	.avatar {
		width: 178px;
		height: 178px;
		display: block;
	}

	.el-submenu__title:hover,
	.el-menu-item:focus,
	.el-menu-item:hover {
		background-color: transparent !important;
	}
</style>